<template>
  <div id="app">
    <magician v-if="magician" />
    <HelloWorld msg="Welcome to Your Vue.js App" :magician="magician" />
  </div>
</template>

<script>
import HelloWorld from "./components/HelloWorld.vue";
import Magician from "./components/Magician.vue";

export default {
  name: "App",
  components: {
    HelloWorld,
    Magician,
  },
  data() {
    return {
      magician: false,
    };
  },
  mounted() {
    window.addEventListener("keydown", (e) => {
      if (e.key == "Escape") {
        this.magician = !this.magician;
      }
    });
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
