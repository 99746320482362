<template>
  <div class="hello" style="height: 100vh">
    <div v-if="magician">
      <textarea
        v-model="allCards"
        style="position: absolute; top: 0px; left: 0px; height: 100%"
      ></textarea>
      <button @click="allCards = ''">reset</button>
    </div>

    <div
      ref="container"
      style="position: relative; margin-left: 50%; margin-top: 25vh"
    ></div>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <button @click="shuffle()">shuffle</button>
    <!-- {{ hidden }} {{ log }} -->
  </div>
</template>

<script>
import firebase from "firebase/app";
import { db } from "../db.js";

export default {
  name: "HelloWorld",
  data() {
    return {
      lastFlipped: 0,
      dragged: 0,
      firstShuffle: 0,
      deck: null,
      allCards: "",
      spectatorRef: null,
      spectatorId: "",
      lastCard: null,
      log: "",
      hidden: false,
    };
  },
  props: {
    msg: String,
    magician: Boolean,
  },
  methods: {
    shuffle() {
      this.snitchShuffle();
      console.log("shuffle");
      if (this.firstShuffle == 0) {
        console.log("first shuffle");

        this.firstShuffle = window.ServerDate.now() / 1000;
      }
      this.deck.cards.forEach(function(card) {
        console.log("why not back");
        card.setSide("back");
        card.fixed = false;
      });
      this.deck.shuffle();
      return;
    },
    snitchCard(card, drag) {
      var now = window.ServerDate.now() / 1000;
      let snitch = {};
      snitch.rank = card.rank;
      snitch.suit = card.suit;
      snitch.drag = drag;
      snitch.seconds = now;

      this.spectatorRef.update({
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        cards: firebase.firestore.FieldValue.arrayUnion(snitch),
      });
    },

    snitchShuffle() {
      this.snitchCard({ rank: 0, suit: 0 }, false);
    },

    getFlippedCard() {
      // 16 seconds is the magic number
      var now = window.ServerDate.now() / 1000;
      var seenit = document.cookie.indexOf("donetheforcealready4") >= 0;
      seenit = false;
      if (!seenit) {
        console.log("time since firstShuffle", now - this.firstShuffle);
        console.log("time since lastFlipped", now - this.lastFlipped);
        if (
          now - this.firstShuffle > 70 &&
          now - this.dragged > 12.4 &&
          now - this.dragged < 35
        ) {
          document.cookie = "donetheforcealready4=yes";
          this.lastFlipped = now;
          return { rank: 4, suit: 2 };
        }
        this.lastFlipped = now;
      }
      return cardOfTheMoment();
    },
  },

  mounted() {
    this.hidden = false;
    document.addEventListener("visibilitychange", (e) => {
      console.log(e);
      this.hidden = !this.hidden;
      this.log += e.type + " " + this.hidden;
    });

    this.spectatorId = uuidv4();
    this.spectatorRef = db.collection("spectators").doc(this.spectatorId);
    this.spectatorRef.set({
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
    let capturedthis = this;

    db.collection("forces")
      .doc(this.spectatorId)
      .onSnapshot((doc) => {
        console.log("got force for " + capturedthis.spectatorId);
        if (doc.data()) {
          if (capturedthis.lastCard) {
            capturedthis.lastCard.rank = doc.data().rank;
            capturedthis.lastCard.suit = doc.data().suit;
            capturedthis.lastCard.setSide("front");
          }
        }
      });

    setInterval(() => {
      let moment = cardOfTheMoment();
      capturedthis.allCards +=
        moment.rank + " of " + SuitName(moment.suit) + "; \n";
    }, 970);
    var $container = this.$refs.container;

    console.log("creatingdeck");
    // create Deck
    this.deck = window.Deck(false, (card) => {
      this.dragged = window.ServerDate.now() / 1000;
      this.snitchCard(card, true);
    });

    // add to DOM
    this.deck.mount($container);
    console.log($container);
    this.deck.shuffle();
    var mythis = this;
    this.deck.cards.forEach(function(card, i) {
      console.log(card);
      let end = () => {
        if (!card.fixed) {
          let moment = mythis.getFlippedCard();
          console.log(moment);
          card.rank = moment.rank;
          card.suit = moment.suit;
          card.setSide("back");
          card.setSide("front");
          setTimeout(card.enableDragging);
          capturedthis.lastCard = card;
          capturedthis.snitchCard(moment, false);
        }
        card.fixed = true;
      };
      card.fixed = true;
      card.$el.addEventListener("touchend", end);
      card.$el.addEventListener("mouseup", end);
      card.setSide("front");
      console.log(i);
    });

    this.snitchCard({
      suit: this.deck.cards[51].suit,
      rank: this.deck.cards[51].rank,
    });
  },
};
function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
let shuffledDeck = [
  37,
  8,
  20,
  35,
  6,
  18,
  42,
  41,
  2,
  50,
  12,
  32,
  15,
  52,
  28,
  4,
  7,
  13,
  44,
  10,
  17,
  40,
  48,
  29,
  24,
  36,
  33,
  38,
  11,
  43,
  5,
  46,
  22,
  39,
  37,
  1,
  19,
  31,
  9,
  34,
  45,
  51,
  3,
  27,
  23,
  49,
  16,
  26,
  25,
  14,
  47,
  21,
];

function cardOfTheMoment() {
  let index = Math.floor((window.ServerDate.now() / 1000) % 52);
  // count++;
  let card = shuffledDeck[index];
  let suit = Math.floor((card - 1) / 13);
  let rank = ((card - 1) % 13) + 1;
  // console.log(shuffledDeck[index] + " is " + rank + " of " + suit)
  return { rank: rank, suit: suit };
}

function SuitName(suit) {
  // return suit name from suit value
  return suit === 0
    ? "spades"
    : suit === 1
    ? "hearts"
    : suit === 2
    ? "clubs"
    : suit === 3
    ? "diamonds"
    : "joker";
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.card {
  transition: transform 0.6s;
  transform-style: preserve-3d;
}
.card .back {
  transform: rotateY(180deg);
}
</style>
