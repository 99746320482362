<template>
  <div style="position: absolute; top: 0px; left: 0px">
    {{ rank }} {{ suit }}
  </div>
</template>
<script>
export default {
  props: {
    rank: Number,
    suit: Number,
  },
  data() {
    return {
      card: {},
    };
  },
  mounted() {
    this.card = window.Deck.Card();
    console.log("snitching for " + this.rank);
    console.log(this.card);
    this.card.setSide("front");

    this.card.setRankSuit(this.rank, this.suit);

    this.$el.appendChild(this.card.$el);
  },
  watch: {
    rank: function () {
      console.log("snitching for " + this.rank + " " + this.suit);

      this.card.setSide("front");

      this.card.setRankSuit(this.rank, this.suit);
    },
    suit: function () {
      this.card.setSide("front");

      this.card.setRankSuit(this.rank, this.suit);
    },
  },
};
</script>