<template>
  <div>
    Magician

    <div
      v-for="spectator in spectators"
      :key="spectator.id"
      style="text-align: right"
    >
      <span
        class="cardholder"
        v-for="(card, index) in spectator.cards
          ? spectator.cards.slice().reverse()
          : []"
        :key="spectator.cards.length - index"
        @click="force(spectator, card)"
      >
        <card
          :title="titleForCard(card, spectator.cards, index)"
          :rank="parseInt(card.rank)"
          :suit="parseInt(card.suit)"
        />
      </span>
      <span class="cardholder" @click="force(spectator, { suit: 2, rank: 7 })"
        ><card :rank="parseInt(7)" :suit="parseInt(2)" />
      </span>
    </div>
  </div>
</template>

<script>
import { db } from "../db.js";

import Card from "./Card.vue";

export default {
  components: {
    Card,
  },
  data() {
    return {
      spectators: [],
    };
  },

  methods: {
    titleForCard(card, cards, index) {
      let reversed = cards.slice().reverse();
      let string = card.drag ? "d\n" : "";
      if (index < reversed.length - 1) {
        string += card.seconds - (index > 0 ? reversed[index + 1].seconds : 0);
      }
      string += "\n";
      string += card.seconds - reversed[reversed.length - 1].seconds;

      return string;
    },
    force(spectator, card) {
      console.log("forcing " + card + " on  " + spectator.id);
      db.collection("forces")
        .doc(spectator.id)
        .set(card);
    },
  },
  firestore: {
    spectators: db
      .collection("spectators")
      .orderBy("createdAt", "desc")
      .limit(6),
  },
};
</script>
<style scoped>
.cardholder {
  height: 30px;
  width: 30px;
  display: inline-block;
  position: relative;
  text-align: left;
}
</style>
