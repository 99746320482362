import firebase from 'firebase/app'
import 'firebase/firestore'

var firebaseConfig = {
    apiKey: "AIzaSyAYVp12FLp9eQWFZOr7MOslhIIIHGLypN4",
    authDomain: "adeckofcards-c5499.firebaseapp.com",
    databaseURL: "https://adeckofcards-c5499.firebaseio.com",
    projectId: "adeckofcards-c5499",
    storageBucket: "adeckofcards-c5499.appspot.com",
    messagingSenderId: "738090358940",
    appId: "1:738090358940:web:e44c80004f693eacd2b559"
};

// Get a Firestore instance
export const db = firebase
    .initializeApp(firebaseConfig)
    .firestore()

// Export types that exists in Firestore
// This is not always necessary, but it's used in other examples
const { Timestamp, GeoPoint } = firebase.firestore
export { Timestamp, GeoPoint }
